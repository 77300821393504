/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      # acf {
      #   image {
      #     localFile {
      #       childImageSharp {
      #         fluid(quality: 100, maxWidth: 1920) {
      #           ...GatsbyImageSharpFluid_withWebp_noBase64
      #         }
      #         meta: fluid(maxWidth: 800, toFormat: JPG) {
      #           src
      #         }
      #       }
      #     }
      #   }
      # }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoastMeta },
  },
  // data: {
  //   page: { path, title, yoastMeta, acf: { image } },
  // },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      {/* <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={image.localFile.childImageSharp.fluid.src}
        article
      /> */}
      <div className="container py-5">
        <TitleDefault>{title}</TitleDefault>
        <ParseContent content="Pagina content" />
      </div>
    </Layout>
  )
}

export default PageTemplate
